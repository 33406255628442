.payment {
    @apply relative bg-secondary-300;

    &__details {
        @apply col-span-3 w-full;
    }

    &__summary {
      @apply flex flex-col md:flex-row items-center justify-between border-b pb-4 bg-white rounded-tr-md rounded-tl-md p-xs shadow-sm;
    }

    &__vehicle {
      @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-content-center px-xs py-3 bg-white rounded-br-md rounded-bl-md mb-5 shadow-sm;

      article {
        @apply flex items-center justify-start px-1;
      }
    }

    
    &__plate {
      @apply relative w-40 h-32;

      font-family: sans-serif;

      &::before {
        @apply absolute top-8 left-4 pl-0.5 text-black text-lg tracking-widest;

        content: attr(data-plate-number);
      }

      &::after {
        @apply absolute bottom-8 left-4 pl-0.5 text-black text-lg tracking-widest;

        content: attr(data-plate-number);
        direction: ltr;
      }

      &__arabic {
        @apply absolute top-8 pt-0.5 text-black;
        
        right: 2rem;
        font-size: 0.775rem;
      }
      &__english {
        @apply absolute text-black;
        
        right: 2rem;
        bottom: 2rem;
      }
  }

    &__images {
      @apply bg-white p-xs rounded-md mb-5 shadow-sm;
      
      &__warning {
        @apply cursor-pointer flex items-start justify-between rounded-md border p-xs text-sm bg-primary-100 border-primary;
      }
    }
    
    &__applicant {
      @apply bg-white p-xs rounded-md shadow-sm;

    }

    &__price {
      @apply bg-white p-xs rounded-md mb-5 shadow-sm mt-5;

      &__item {
        @apply flex items-start justify-between gap-4 my-2 pb-2 border-b;

        p {
          @apply text-sm text-secondary;
        }

        ul {
          @apply text-xs max-w-lg;
        }

        li {
          @apply py-1;
        }
      }

      &__item:last-child {
        @apply border-0;
      }
    }

    &__methods {
      @apply bg-white p-xs rounded-md mb-5 shadow-sm lg:sticky lg:top-20;
    }
}