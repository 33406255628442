@import "../qariin-fe/src/styles/main.css";

fieldset.email {
    --icon: url("@qariin-fe/src/images/icons/email.png");
}

fieldset.mobile {
    --icon: url("@qariin-fe/src/images/icons/mobile.png");
}

fieldset.password {
    --icon: url("@qariin-fe/src/images/icons/password.png");
}

.header__mobile.identity {
    /*--background: url('@qariin-fe/src/images/identity.png')*/
}