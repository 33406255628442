.header {
  @apply sticky top-0 left-0 shadow-md bg-white py-2 z-40;

  &__nav {
    @apply flex items-center justify-between text-secondary;

    img[alt="LOGO"] {
      min-width: 110px;
    }

    a {
      @apply transition-colors duration-300;

      &:hover {
        @apply text-primary;
      }
    }
  }

  &__routes {
    @apply ms-5 hidden lg:flex items-center justify-start flex-grow text-sm;
  }

  &__buttons {
    @apply flex items-center justify-center text-primary-500;

    button {
      @apply transform scale-75 mx-1;
    }

    a {
      @apply rounded-md border border-primary-500;
    }

    &__toggler {
      @apply lg:hidden;
    }
  }

  &__mobile {
    @apply fixed top-0 left-0 w-screen h-screen bg-white z-50 px-md pt-16 transition-transform duration-200 font-semibold;

    transform: translate3d(100%, 0, 0);

    &__close {
      @apply absolute top-0 left-2 text-5xl z-40;
    }

    &__back {
      @apply absolute top-4 right-2 text-6xl z-40;
    }

    &__top {
      @apply absolute top-0 left-0 w-full h-full pt-20 max-h-screen overflow-y-scroll overflow-x-hidden transition-transform duration-200;

      transform: translate3d(0, 0, 0);

      &.child-opened {
        @apply overflow-visible;

        transform: translate3d(100%, 0, 0);
      }
    }

    &__child {
      @apply absolute top-0 left-0 w-full h-full pt-20 px-xs max-h-screen overflow-y-scroll transition-transform duration-200 bg-white z-10;

      transform: translate3d(-100%, 0, 0);
    }

    &.open {
      transform: translate3d(0, 0, 0);
    }

    a {
      @apply w-full border-b justify-start text-xl py-6;

      border-bottom-width: 1px;
    }
  }
}
