@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;600;700&display=swap');

/* Global Styles */
@import './modules/_header.css';
@import './modules/_footer.css';

/* Pages Styles */
@import './modules/_homepage.css';
@import './modules/_staticPages.css';
@import './modules/_about.css';
@import './modules/_faqs.css';
@import './modules/_offers.css';
@import './modules/_vehicle.css';
@import './modules/_payment.css';
@import './modules/_contactus.css';
@import './modules/_otherInfo.css';
@import './modules/_profile.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetical';
  src: url('@qariin-fe/src/fonts/HelveticaNeueLTArabic-Light_3.ttf')  format('truetype'),
    url("@qariin-fe/src/fonts/HelveticaNeueLTStd Lt_0.otf") format("opentype")
}

* {
  font-family: inherit;
  font-weight: inherit;
  outline: none;
}

input::-moz-focus-inner,
input:-moz-focusring {
  border: 0;
  outline: 0;
}

body,
html {
  @apply text-primary font-semibold;

  max-width: 100vw;
  min-height: 100vh;
  font-family: 'Helvetical', sans-serif;
  scroll-behavior: smooth;
}

.remove-scroll {
  @apply overflow-hidden;
}

.container {
  @apply mx-auto px-xs;

  max-width: 1200px;
}

a,
button {
  @apply inline-flex items-center justify-center;

  min-height: 48px;
  min-width: 48px;
}

.disabled {
  @apply opacity-25 cursor-not-allowed;
}

.input-disabled {
  @apply opacity-50 cursor-not-allowed select-none;

  input, label {
    @apply cursor-not-allowed;
  }
}

.input {
  @apply relative border py-1 ps-2 rounded-md flex flex-col items-start pe-10 w-64 mb-2;

  label {
    @apply text-xs pb-1;
  }

  input {
    @apply w-full;

    &::placeholder {
      @apply text-xs;
    }
  }

  textarea {
    @apply w-full;
  }

  &::after {
    @apply absolute top-1/2 left-2 w-6 h-6 bg-center bg-contain bg-no-repeat transform -translate-y-1/2;

    content: '';
    background-image: var(--icon);
  }

  &-no-icon {
    @apply pe-0;

    &::after {
      @apply hidden;
    }
  }

  &-w-auto {
    @apply w-full max-w-xs;
  }
  &-w-full {
    @apply w-full;
    @apply w-full;
  }
}

.input-normal {
  input {
    @apply relative w-full p-2 border rounded-md text-secondary mt-1 pe-8;
  }

  label {
    @apply text-xs text-secondary;
  }

  &--xs {
    @apply max-w-xs;
  }
}

.btn {
  @apply bg-primary text-white w-52 rounded-md;
}

input[type='radio'] {
  @apply relative w-0 h-0 ms-4 me-1 invisible;

  &::before {
    @apply absolute -top-3 left-0 w-4 h-4 rounded-full border border-primary visible;

    content: '';
  }

  &::after {
    @apply absolute w-3 h-3 rounded-full bg-primary opacity-0 transition-opacity duration-300 visible;

    content: '';
    top: calc(-0.75rem + 2px);
    left: 2px;
  }

  &:checked {
    &::after {
      @apply opacity-100;
    }
  }
}

input[type='checkbox'] {
  @apply relative w-0 h-0 invisible ms-4 me-1;

  -moz-appearance: initial;

  &::before {
    @apply absolute -top-3 left-0 w-4 h-4 border border-primary visible;

    content: '';
    border-radius: 3px;
  }

  &::after {
    @apply absolute -top-3 left-0 w-4 h-4 bg-primary opacity-0 transition-opacity duration-300 text-white flex items-center justify-center text-xs p-px visible;

    content: '✓';
    border-radius: 3px;
  }

  &:checked {
    &::after {
      @apply opacity-100;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.date_select {
  @apply px-2 bg-white;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &::-webkit-scrollbar {
      width: 0px;
  }
}

.selectbox {
  @apply relative w-full text-black max-w-xs;

  select {
    @apply relative w-full p-2 border rounded-md text-secondary mt-1 pe-8 bg-white;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  &.min {
    @apply w-44;

    select {
      @apply border-0;
    }
  }

  &::after {
    @apply absolute bottom-2 left-2 w-5 h-5 bg-contain;

    content: '';
    background-image: var(--icon);
  }

  label {
    @apply text-xs text-secondary;
  }
}

.dropdown {
  @apply relative flex items-center justify-center mx-xs z-30;

  ul {
    @apply absolute top-12 left-1/2 transform -translate-x-1/2 w-48 bg-white p-xs rounded-md shadow-lg z-30 hidden;

    &::before {
      @apply absolute -top-12 left-1/2 transform -translate-x-1/2 w-32 h-16 cursor-pointer;

      content: '';
    }

    a {
      @apply text-sm min-h-full min-w-full justify-start py-2;
    }
  }

  &:hover {
    ul {
      @apply grid;
    }
  }

  &__generalInsurances {
    @apply grid-cols-2 gap-y-3;
    
    width: 500px!important;

    a {
      @apply border-b pb-2;

      font-size: .8em!important;
    }
  }
}

.generalInsurance {
  @apply hidden lg:flex;
}

.modals {
  @apply absolute top-0 left-0 z-50 w-screen h-screen py-5;

  background-color: rgba(0, 0, 0, 0.5);

  .container {
    @apply h-full flex items-center justify-center;
  }

  &__modal {
    @apply relative bg-white max-w-4xl max-h-full bg-white p-xs rounded-lg;

    &__close {
      @apply absolute top-0 right-0 bg-white rounded-full flex items-center justify-center text-3xl text-black;
    }
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__addons {
    @apply flex flex-col items-start justify-center my-6;

    &__button {
      @apply flex items-center justify-center text-sm rounded-md px-xs py-1 min-h-full transition-colors duration-200 my-1;

      .icon {
        @apply flex items-center justify-center h-5 w-5 border border-primary rounded-full pb-1 me-2 font-bold text-base transition-colors duration-200;
        min-width: 1.25rem;
        min-height: 1.25rem;
      }

      &:hover {
        @apply bg-primary-100 shadow-sm;

        .icon {
          @apply bg-primary text-primary-100;
        }
      }

      &--red {
        &:hover {
          @apply bg-secondary-50 text-danger shadow-sm;

          .icon {
            @apply bg-danger text-secondary-50 border-danger;
          }
        }
      }

      &--green {
        &:hover {
          @apply bg-secondary-50 text-success shadow-sm;

          .icon {
            @apply bg-success text-secondary-50 border-success;
          }
        }
      }
    }
  }

  button[type='submit'] {
    @apply bg-primary text-white rounded-md px-12;
  }
}

.profileMenu {
  &::-webkit-scrollbar {
    display: none;
  }
}

.modal {
  @apply fixed top-0 left-0 z-50 w-screen h-screen py-5;

  background-color: rgba(0, 0, 0, 0.5);

  .container {
    @apply h-full flex items-center justify-center;
  }

  &__content {
    @apply relative bg-white w-full max-w-4xl max-h-full bg-white p-xs rounded-lg;

    &__close {
      @apply absolute top-0 left-0 bg-white rounded-full flex items-center justify-center text-3xl text-black;
    }

    & > div {
      @apply overflow-y-scroll overflow-x-hidden;

      max-height: calc(100vh - 200px);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.wide {
      @apply max-w-full md:mx-sm;
    }
    
    &--md {
      @apply max-w-md;
    }
  }
}

.table_container {

  @apply overflow-x-scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

table {
  @apply w-full text-secondary rounded-md text-center text-sm mb-6;

  thead {
    @apply bg-secondary-100;

    th {
      @apply py-1 h-12;

      min-width: 140px;
    }
  }

  tbody {
    tr {
      @apply bg-secondary-50 h-12;

      &:not(:last-of-type) {
        @apply border-b;
      }

      &.active {
        @apply bg-secondary-100;
      }
    }
  }
}

.profile {
  &__plate {
    @apply relative w-32 h-16;

    font-family: sans-serif;

    &::before {
      @apply absolute top-0 left-2 pt-0.5 pl-0.5 text-black text-lg tracking-widest;

      content: attr(data-plate-number);
    }

    &::after {
      @apply absolute bottom-0 left-2 pb-0.5 pl-0.5 text-black text-lg tracking-widest;

      content: attr(data-plate-number);
      direction: ltr;
    }

    &__arabic {
      @apply absolute top-2 text-black;
      
      right: 1.5rem;
      font-size: 0.65rem;
    }
    &__english {
      @apply absolute text-black text-sm;
      
      right: 1.6rem;
      bottom: 0.5rem;
    }
  }
}

html[dir='ltr'] {
  .input {
    &::after {
      @apply left-auto right-2;
    }
  }
  .selectbox {
    select {
      &::after {
        @apply left-auto right-2;
      }
    }
  }

  input[type='checkbox'] {
    @apply me-6 ms-1;
  }

  input[type='radio'] {
    @apply me-6 ms-1;
  }

  .modal {
    &__content {
      &__close {
        @apply left-auto right-0;
      }
    }
  }
}
