@keyframes load-circle {
    0% {
        stroke-dashoffset: 100;
    }
}

.o-progress-circle {
    @apply relative w-52 h-52;

    &__fill {
        circle {
            fill: none;
            stroke-width: 1px;
            stroke: #ddd;
            &:nth-child(2) {
                animation: load-circle 3s;
                stroke-width: 2px;
                stroke: #208C7B;
                stroke-dasharray: 100;
            }
        }        

    }

    &__number {
        @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-secondary-900 flex flex-col items-center justify-center text-center;

        span {
            @apply pb-2;
        }
    }
    &--rounded {
      circle {
            &:nth-child(2) {
                stroke-linecap : round;
            }
        }
    }
}