.contactus {
    @apply relative;

    &__info {
        @apply bg-white py-16 relative lg:shadow-md px-xs;

        & > * {
            @apply relative z-10;
        }

        &::before {
            @apply hidden lg:block absolute top-0 transform translate-x-1/2 w-full h-full bg-white z-0;
            content: '';
        }
    }

    &__socials {
        @apply grid grid-cols-1 lg:grid-cols-4 gap-5 text-end mt-5;

        a {
            @apply min-h-full items-start justify-start;
        }
    }

    &__form {
        @apply py-16 flex flex-col items-center justify-center;

        textarea {
            @apply resize-none;
        }
    }
}