.otherInfo {
    @apply relative max-w-sm mx-auto my-16;

    &__addons {
        @apply flex flex-col items-start justify-between col-span-2 overflow-x-scroll w-full max-w-xs mx-auto;
    
        &::-webkit-scrollbar {
            width: 0px;
        }

        &__button {
            @apply flex items-center justify-between w-full rounded-md my-2 px-xs py-3 min-h-full bg-primary-100 transition-colors duration-200 whitespace-nowrap;

            min-width: 180px;
            .icon {
                @apply flex items-center justify-center h-5 w-5 border border-primary rounded-full pb-1 ms-2 font-bold text-base transition-colors transition-opacity duration-200;
                min-width: 1.25rem;
                min-height: 1.25rem;
            }

            &:hover {
                @apply shadow-sm;

                .icon {
                    @apply bg-primary text-primary-100;
                }
            }

            &--hidden {
                @apply mt-5;
                .icon {
                    @apply opacity-0;
                }

                &:hover {
                    .icon {
                        @apply opacity-100;
                    }
                }
            }

            &--download {
                @apply max-w-xs;
                min-height: 0;
            }
        }
    }
}