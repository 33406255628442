.footer {
  @apply relative bg-primary pt-12;

  &__bg {
    @apply absolute top-0 h-full z-0 bg-no-repeat bg-right bg-cover;
    left: 15px;
    width: 100%;
    background-image: var(--background);
  }

  &__links {
    @apply grid grid-cols-1 md:grid-cols-4 gap-4 text-secondary-200 relative z-10;

    &__lane {
      @apply flex flex-col items-start justify-start ms-5 md:ms-0 md:me-6 lg:me-12;
    }

    h3 {
      @apply mb-4;
    }

    a {
      @apply text-xs my-1;

      min-height: auto;

      &:hover {
        @apply text-white;
      }
    }
  }

  &__info {
    @apply w-full md:w-auto text-white border-t md:border-0 mt-5 lg:mt-0 relative z-10;

    a {
      @apply min-h-full;
    }
  }

  &__socials {
    @apply mt-5 pb-4 border-b border-primary-500 relative z-10;
  }

  &__copyright {
    @apply text-white text-xs flex flex-col md:flex-row items-center justify-between py-4 relative z-10;

    a {
      @apply min-h-full me-1;
    }
  }
}
