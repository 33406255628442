.staticPage {
  @apply relative;

  &::before {
    @apply absolute bottom-0 left-0 w-full h-2/5 bg-secondary-300 z-0;

    content: '';
  }

  &--no-bg {
    &::before {
      @apply hidden;
    }
  }

  &__info {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12 relative z-10;
  }

  &__poster {
    @apply w-full max-w-lg relative z-10;

    max-height: 400px;
  }

  &__features {
    @apply relative flex flex-wrap items-start md:justify-center lg:justify-start pb-10 z-10;
  }

  &__feature {
    @apply relative w-full max-w-sm shadow-lg border-t-2 border-primary-500 p-3 m-xs z-10 bg-white z-10;

    &--fullWidth {
      @apply max-w-3xl;
    }
  }

  &__featureTitle {
    @apply text-black pb-2 border-b mb-2 relative z-10;
  }

  &__list {
    li {
      @apply relative ps-8 py-1 text-secondary text-sm z-10;

      &::before {
        @apply absolute top-1 right-0 w-5 h-5 flex items-center justify-center text-primary rounded-full;

        content: '✔';
        background-color: #92AEC7;
      }
    }

    &--twoCols {
      @apply flex items-start justify-start;

      &-lg {
        @apply flex-col lg:flex-row;
      }
      &-md {
        @apply flex-col md:flex-row;
      }
    }

    &--disabled {
      li {
        &::before {
          @apply absolute top-1 right-0 w-5 h-5 flex items-center justify-center text-primary rounded-full bg-secondary-500;

          content: '';
        }
      }
    }

    div {
      @apply me-8;
    }
  }
}

html[dir='ltr'] {
  .staticPage {
    &__list {
      &::before {
        @apply right-auto left-0;
      }
    }
  }
}
