.offers {
  &__vehicle {
    @apply grid grid-cols-1 lg:grid-cols-3 gap-3 place-content-center pt-12 pb-3 border-b;

    &__info {
      @apply grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 place-content-center mb-3;

      article {
        @apply flex items-center justify-start px-1;
      }

      article:not(:last-of-type) {
        div {
          @apply w-full lg:border-e;
        }
      }
    }
  }
  &__addons {
    @apply flex items-center justify-end col-span-2 overflow-x-scroll;

    &::-webkit-scrollbar {
        width: 0;
    }

    &__button {
      @apply flex items-center justify-start text-sm rounded-md px-xs py-1 min-h-full transition-colors duration-200 whitespace-nowrap;

      min-width: 180px;
      .icon {
        @apply flex items-center justify-center h-5 w-5 border border-primary rounded-full pb-1 me-2 font-bold text-base transition-colors duration-200;

        min-width: 1.25rem;
        min-height: 1.25rem;
      }

      &:hover {
        @apply bg-primary-100 shadow-sm;

        .icon {
          @apply bg-primary text-primary-100;
        }
      }
    }
  }
  &__list {
    @apply bg-secondary-300 py-10;

    
    .dimmed {
      @apply relative;

      &::after {
        @apply w-full h-full absolute top-0 left-0 z-10 cursor-wait backdrop-blur-sm;
        
        content: '';
      }
    }

    & div {
      
      &::-webkit-scrollbar {
          width: 0;
      }
    }
  }

  &__offer {
    @apply bg-white rounded-md shadow-lg my-5;

    &__title {
      @apply p-2 flex items-center justify-between border-b;
    }
  }

  &__features {
    li {
      @apply relative ps-8 py-1 mb-2 text-black text-xs;

      &::before {
        @apply absolute top-0.5 right-0 w-5 h-5 flex items-center justify-center text-primary rounded-full;

        content: '✓';
        background-color: #92AEC7;
      }
    }
  }

  &__noFeatures {
    li {
      @apply relative py-1 mb-2 text-black text-xs select-none;

      &.empty {
        @apply ps-8;
        &::before {
          @apply absolute top-0.5 right-0 w-5 h-5 flex items-center justify-center border border-primary-400 text-primary-400 rounded-full;

          content: '';
        }
      }
    }
  }

  &__plate {
    @apply relative w-40 h-32;

    font-family: sans-serif;

    &::before {
      @apply absolute top-8 left-4 pl-0.5 text-black text-lg tracking-widest;

      content: attr(data-plate-number);
    }

    &::after {
      @apply absolute bottom-8 left-4 pl-0.5 text-black text-lg tracking-widest;

      content: attr(data-plate-number);
      direction: ltr;
    }

    &__arabic {
      @apply absolute top-8 pt-0.5 text-black;

      right: 2rem;
      font-size: 0.775rem;
    }
    &__english {
      @apply absolute text-black;

      right: 2rem;
      bottom: 2rem;
    }
  }

  &__companies {
    @apply min-h-full bg-white px-8 py-2 rounded-md me-3 mb-0.5;
  }
  &__deductables {
    select {
      direction: ltr;
    }
  }

  &__pricing {
    @apply w-full mt-3;

    li {
      @apply w-full flex items-center justify-between text-secondary px-xs text-sm py-2;
    }

    small {
      @apply pe-3 truncate;
    }
    b {
      @apply whitespace-nowrap;
    }
  }

  &__buyNow {
    @apply w-48 bg-primary text-white rounded-md flex items-center justify-center mx-auto mt-5 mb-2 text-sm;
  }

  &__fullPackage {
    @apply w-48 bg-secondary-100 text-primary rounded-md flex flex-col items-center justify-center mx-auto mb-3;
    span {
      @apply text-sm;
    }
  }
  &__vehicleValue{
    @apply w-48 p-3 bg-gray-200 text-secondary rounded-md flex flex-col items-center justify-center mx-auto mb-3;
    span {
      @apply text-sm;
    }
  }
}
