.faqs {
  &__header {
    @apply bg-tertiary-50 py-12;

    h1 {
      @apply text-xl lg:text-3xl text-black mb-5;
    }

    p {
      @apply text-secondary max-w-2xl;
    }

    ul {
      @apply flex items-center justify-start overflow-x-scroll mt-5;

      a {
        @apply whitespace-nowrap px-xs min-h-full text-white bg-primary rounded-md py-2 me-2 text-sm;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__subHeading {
    @apply text-3xl text-black my-8 border-b py-6;
  }

  &__question {
    @apply flex items-start justify-start cursor-pointer max-w-3xl;

    span {
      @apply text-black text-xl;
    }
  }

  &__icon {
    @apply w-8 h-8 bg-primary-200 rounded-full p-1.5 flex items-center justify-center me-4 shadow-md;

    min-width: 2rem;
    min-height: 2rem;

    &__light {
      @apply hidden transform rotate-180;
    }

    &__dark {
      @apply block;
    }
  }

  &__answer {
    @apply text-secondary ps-12 mt-5 max-w-3xl h-0 overflow-hidden overflow-y-scroll transition-all duration-300;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__faq {
    @apply border-b pb-6 mt-6;

    &.active {
      .faqs__icon {
        @apply bg-primary;
      }

      .faqs__icon__light {
        @apply block;
      }

      .faqs__icon__dark {
        @apply hidden;
      }

      .faqs__answer {
        @apply h-36;

        &--heigh {
          height: 400px;
        }
      }
    }
  }
}
