.vehicle {
  &__addons {
    @apply flex flex-col items-start justify-start mt-5 max-w-xs mx-auto;

    &__button {
      @apply flex items-center justify-center text-sm rounded-md px-xs py-2 min-h-full transition-colors duration-200 my-1;

      .icon {
        @apply flex items-center justify-center h-5 w-5 border border-primary rounded-full pb-1 me-2 font-bold text-base transition-colors duration-200;
      }

      &:hover {
        @apply bg-primary-100 shadow-sm;

        .icon {
          @apply bg-primary text-primary-100;
        }
      }
    }
  }
}