.homepage {
  @apply relative;

  &__categories {
    @apply border-b;

    background: linear-gradient(to bottom, #dde5ed3b, #fff);

    &__main {
      @apply flex items-center justify-start;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__category {
      a::before {
        @apply absolute top-1/2 left-0 h-1/2 w-px bg-tertiary-400 transform -translate-y-1/2;

        content: '';
      }

      a {
        @apply relative px-xs relative text-secondary-500 whitespace-nowrap text-sm lg:text-base;

        &.active {
          @apply text-primary font-bold border-b-4 border-b-primary;
        }
      }
    }
  }

  &__content {
    @apply flex flex-col items-start justify-center py-12;
  }

  &__recommended {
    @apply py-12 relative;

    &::before {
      @apply absolute top-0 left-0 w-full h-1/2;

      content: '';
      background: linear-gradient(to bottom, #dde5ed3b, #fff);
    }

    .swiper-wrapper {
      @apply mt-12;
    }

    article {
      @apply flex items-center justify-center h-32;
    }
  }

  &__guide {
    @apply relative py-20;

    background-image: url('@qariin-fe/src/images/guide-bg.jpg');
    background-position: 20%;
    background-size: cover;
    
    @screen md {
      background-position: center;
    }

    &__steps {
      @apply flex flex-col md:flex-row items-center justify-center;
    }

    article {
      @apply w-40 flex flex-col items-center justify-center py-2 md:py-10 text-center;

      figure {
        @apply flex items-center justify-center w-24 h-24 p-5 rounded-full border border-primary bg-primary-100;

        img {
          @apply max-w-full max-h-full;
        }
      }

      p {
        @apply text-white text-sm mt-4 whitespace-nowrap;
      }
    }

    .step {
      @apply relative h-32 md:h-auto w-40 text-center text-primary-900 flex items-center lg:mb-6;

      span {
        @apply relative z-20 flex items-center justify-center w-10 h-10 p-1 rounded-full bg-primary-200 mx-auto border border-primary;
      }

      &::before {
        @apply absolute top-0 md:top-1/2 left-1/2 md:left-0 w-px md:w-full h-full md:h-px bg-primary z-10 transform -translate-x-1/2 md:translate-x-0 md:-translate-y-1/2;

        content: '';
      }
    }
  }

  &__why-qariin {
    @apply py-12 bg-secondary-50;
  }
}

html[dir='ltr'] {
  .homepage {
    &__categories {
      li:not(:last-child) {
        a::before {
          @apply right-0 left-auto;
        }
      }
    }
  }
}
