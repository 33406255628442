.about {
  @apply relative;

  &__poster {
    @apply relative;

    &::before {
      @apply absolute top-0 left-0 w-full h-5/6 bg-tertiary-50 z-0;

      content: '';
    }
  }

  &__features {
    @apply text-center mt-4;

    img {
      @apply w-16 h-16 mx-auto;
    }

    span {
      @apply inline-block text-secondary pt-5 w-28;
    }
  }

  &__vision {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-5 bg-secondary-100 rounded-md py-16 px-8 mb-10 lg:mb-32;

    h2 {
      @apply text-xl lg:text-3xl mb-2;
    }

    p {
      @apply max-w-xl;
    }

    figure {
      @apply relative hidden lg:block w-full h-full;

      img {
        @apply absolute -bottom-36 left-1/2 transform -translate-x-1/2;

        height: calc(100% + 11rem);
      }
    }
  }

  &__values {
    @apply relative mb-12;

    &__poster {
      @apply hidden lg:block;

      img {
        max-height: 300px;
      }
    }
  }
}
